<template>
  <div class="console_r">
    <div class="console_r_card">
      <div class="title">
        <b>账号列表</b>
        <span>当前记录：{{count}}</span>
      </div>
      <div class="con">
        <div class="con_top" style="padding: 30px 0;">
          <!-- <sh-input placeholder="请输入家禽ID" v-model="currSearchChickenId" style="padding: 30px 0; width: 40%">
            <sh-button class="search-btn" slot="append" icon="el-icon-search" @click="searchChickenId"></sh-button>
          </sh-input> -->
          <!-- <sh-input style="width: 300px" v-model="queryParams.file_name" placeholder="请输入账户名"></sh-input> -->
          <!-- <el-button type="primary" @click="search" round>查询</el-button> -->
          <el-button type="primary" @click="viewAdd" round>新增</el-button>
        </div>
        <el-table :data="list" border>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="账号"></el-table-column>
          <el-table-column prop="last_upload_image_date" label="上一次上传时间"></el-table-column>
          <el-table-column prop="total_upload_image_count" label="总图片张数"></el-table-column>
          <el-table-column prop="date" label="创建时间"></el-table-column>
          <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="viewModify(scope.row)" round>修改</el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center">
        <el-pagination
        style="padding: 20px 0 40px"
        background
        layout="prev, pager, next"
        @current-change="currPageChange"
        :page-size="queryParams.limit"
        :total="count">
        </el-pagination>
      </div>
    </div>
    <el-dialog 
    class="dialog"
    :title="!postParams.sub_account_id ? '新增' : '修改'" 
    width="800px"
    :visible.sync="addDialogVisible">
      <div class="detail-item">
        <el-form ref="postParams" :model="postParams" label-width="140px">
          <el-form-item label="账号名称：">
            <el-input :disabled="!!postParams.sub_account_id" v-model="postParams.name" placeholder="请输入账号名称"></el-input>
          </el-form-item>
          <el-form-item label="账号密码：">
            <el-input v-model="postParams.password" :placeholder="!postParams.sub_account_id ? '请输入账号密码' : '请输入修改密码'"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right;">
          <el-button type="primary"
          @click="submit" 
          :disabled="!postParams.name || !postParams.password"
          >提 交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import crypto from 'crypto'
import { Message } from 'element-ui';
import { addAccountApi, getAccountListApi, getAccountCountApi, modifyAccountPasswordApi } from '@/api/user'
import config from '@/config'

export default {
  name: 'PoultryIndex',
  components: {},
  data() {
    return {
      loadingData: false,
      queryParams: {
        skip: 0,
        limit: 8,
      },
      list: [],
      count: 0,
      postParams: {
        sub_account_id: null,
        name: null,
        password: null,
      },
      // editPostParams: {
      //   sub_account_id: null,
      //   password: null,
      // },
      addDialogVisible: false
    }
  },
  methods: {
    getList () {
      this.loadingData = true
      getAccountListApi(this.queryParams).then(res=>{
        this.list = res.data.data
        this.list.forEach((item) => {
          item.last_upload_image_date = item.last_upload_image_ts ? moment(item.last_upload_image_ts).format("YYYY-MM-DD HH:mm:ss") : ''
          item.date = moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
        })
        this.loadingData = false
      }).catch(() => {
        this.loadingData = false
      })
    },  
    getCount () {
      getAccountCountApi(this.queryParams).then(res => {
        this.count = res.data.data.count
      })
    },
    currPageChange (currPage) {
      this.queryParams.skip = (currPage - 1) * this.queryParams.limit
      this.getList(this.queryParams)
    },
    viewAdd () {
      for(let k in this.postParams) {
        this.postParams[k] = null
      }
      this.addDialogVisible = true
    },
    viewModify (obj) {
      this.addDialogVisible = true
      this.postParams.sub_account_id = obj.id
      this.postParams.name = obj.name
      this.postParams.password = null
    },
    submit () {
      let api = null
      let des = ''
      if(!!this.postParams.sub_account_id) {
        api = modifyAccountPasswordApi
        des = '修改'
      } else {
        api = addAccountApi
        des = '新增'
      }
      let hash = crypto.createHash('sha256')
      let password = hash.update(this.postParams.password).digest('hex')
      api({...this.postParams, password, new_password: password}).then(res=>{
        this.getCount(this.queryParams)
        this.getList(this.queryParams)
        this.addDialogVisible = false
        Message.success(des + '成功')
      }).catch(() => {
        Message.error(des + '失败')
      })
    },
    modifyPassword () {
      modifyAccountPasswordApi(this.editPostParams).then(res=>{
        this.getCount(this.queryParams)
        this.getList(this.queryParams)
        // for(let k in this.editPostParams) {
        //   this.currViewDetailData[k] = this.editPostParams[k]
        // }
        Message.success('修改成功')
      }).catch(() => {
        Message.error('修改失败')
      })
    },
  },
  watch: {
  },
  created(){
    this.getCount(this.queryParams)
    this.getList(this.queryParams)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'poultry.scss'
</style>
